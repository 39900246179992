'use client'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { CategoryDTO, DynamicWidgetProps, StorefrontWidget } from 'ecosystem'
import { CategoryToolbarWidgetType } from 'storefront-modules/categories/widgets/CategoryToolbarWidget'
import { CategoryToolbarWidget } from 'storefront-modules/categories'
import { useSlideOut } from '#/src/custom/controllers'
import { BreadCrumbItem } from 'ui'
import { useRouter } from 'next/navigation'
import { useDispatch, useSelector } from 'shared-redux'
import { selectedNavigateToCategory, setNavigateToCategoryId } from 'shared-redux/state'
import { categoryFactory } from 'api'
import { usePageContext } from 'storefront-modules/page'
import useAppConfig from '#/src/custom/hooks/useAppConfig'

interface CustomCategoryToolbarWidgetProps {
  widget: StorefrontWidget<CategoryToolbarWidgetType>
  dynamicProps?: DynamicWidgetProps
}

const CustomCategoryToolbarWidget: FC<CustomCategoryToolbarWidgetProps> = ({
  dynamicProps,
  widget
}) => {
  const appConfig = useAppConfig()
  const { openSlideOut } = useSlideOut()
  const router = useRouter()
  const dispatch = useDispatch()
  const navigateToCategoryId = useSelector(selectedNavigateToCategory)
  const category = dynamicProps?.obj as CategoryDTO | undefined
  const { categories } = usePageContext()

  const handleOnMore = useCallback(() => {
    if (!category) return
    openSlideOut({
      title: `${category.name}`,
      componentId: 'moreCategories',
      extraArgs: {
        category
      },
      drawerProps: {
        placement: 'right',
        size: 'md'
      }
    })
  }, [category, openSlideOut])

  const parentCategories = useMemo(() => {
    const res = categoryFactory(category?.slug || '', categories)
    return res.parentCategories
  }, [categories, category?.slug])

  const mappedParentsToBreadCrumbItems: BreadCrumbItem[] = parentCategories.map((p) => ({
    label: p.name,
    path: `${appConfig.paths.categoriesUrlPath}/${p.slug}`
  }))

  const breadcrumbs = useMemo<BreadCrumbItem[]>(() => {
    return [
      ...mappedParentsToBreadCrumbItems,
      {
        label: category?.name || '',
        path: `${appConfig.paths.categoriesUrlPath}/${category?.slug}`,
        isCurrent: true
      }
    ]
  }, [
    appConfig.paths.categoriesUrlPath,
    category?.name,
    category?.slug,
    mappedParentsToBreadCrumbItems
  ])

  const navigateToCategory = useCallback(async () => {
    router.push(`${appConfig.paths.categoriesUrlPath}/${navigateToCategoryId}`)
  }, [appConfig.paths.categoriesUrlPath, navigateToCategoryId, router])

  useEffect(() => {
    if (navigateToCategoryId) {
      navigateToCategory().then(() => dispatch(setNavigateToCategoryId({ categoryId: null })))
    }
  }, [dispatch, navigateToCategory, navigateToCategoryId])

  if (!category) {
    return null
  }

  return (
    <CategoryToolbarWidget
      categoriesUrlPath={appConfig.paths.categoriesUrlPath}
      onMore={handleOnMore}
      widgetId={widget.widget.name}
      {...{ widget, category, breadcrumbs }}
    />
  )
}

export default CustomCategoryToolbarWidget
