'use client'
import type { FC } from 'react'
import { CategoryDTO, DynamicWidgetProps, StorefrontWidget } from 'ecosystem'
import { CategoryToolbarWidgetType } from 'storefront-modules/categories/widgets/CategoryToolbarWidget'
import { CategoryTreeWidget } from 'storefront-modules/categories'
import useAppConfig from '#/src/custom/hooks/useAppConfig'

interface CustomCategoryToolbarWidgetProps {
  widget: StorefrontWidget<CategoryToolbarWidgetType>
  dynamicProps?: DynamicWidgetProps
}

const CustomCategoryToolbarWidget: FC<CustomCategoryToolbarWidgetProps> = ({
  widget,
  dynamicProps
}) => {
  const appConfig = useAppConfig()

  const category = dynamicProps?.obj as CategoryDTO | undefined

  if (!category) {
    return null
  }

  return (
    <CategoryTreeWidget
      categoriesPath={appConfig.paths.categoriesUrlPath}
      widgetId={widget.widget.name}
      {...{ widget, category }}
    />
  )
}

export default CustomCategoryToolbarWidget
