'use client'

import { FC } from 'react'
import { Contact, IContactProps } from 'storefront-modules/contact'
import { useModal } from '#/src/custom/controllers'
import { ContactUsFormOverrides } from 'forms'
import { OverridesContextProvider, OverridesContextType } from 'ui/lib/overrides'
import { useTranslations } from 'next-intl'

export interface CustomContactProps extends Omit<IContactProps, 'apiPath' | 'sectionId'> {}

const CustomContact: FC<CustomContactProps> = ({ submitButtonProps, onSuccess, ...props }) => {
  const { openModal } = useModal()
  const t = useTranslations('contact.CustomContactForm')

  const textOverrides: ContactUsFormOverrides = {
    contactUsFormName: t('name'),
    contactUsFormNamePlaceholder: t('namePlaceholder'),
    contactUsFormPhonePlaceholder: t('phonePlaceholder'),
    contactUsFormEmailPlaceholder: t('emailPlaceholder'),
    contactUsFormProductPlaceholder: t('productPlaceholder'),
    contactUsFormOrderNumberPlaceholder: t('orderNumberPlaceholder'),
    contactUsFormDescriptionPlaceholder: t('descriptionPlaceholder')
  }

  const onSuccessCb = () => {
    openModal({
      title: t('confirmation.title'),
      componentId: 'confirmation',
      size: 'md'
    })
  }

  return (
    <OverridesContextProvider overrides={textOverrides as OverridesContextType}>
      <Contact
        apiPath="/api/contact/send-contact"
        onSuccess={onSuccess || onSuccessCb}
        sectionId="contact"
        submitButtonProps={{
          borderRadius: 'md',
          ...submitButtonProps
        }}
        {...props}
      />
    </OverridesContextProvider>
  )
}

export default CustomContact
