'use client'
import { type StorefrontWidget, type WidgetTagProducts } from 'ecosystem'
import { useMemo } from 'react'
import type { OverridesContextType } from 'ui/lib/overrides'
import useProductRequest from '#/src/custom/products/useProductRequest'
import { useCustomCart } from '#/src/custom/cart/useCustomCart'
import TagProductsWidgetBase from 'storefront-modules/products/widgets/TagProductsWidgetBase'
import { useGetProductsQuery } from 'shared-redux/state'
import useAppConfig from '#/src/custom/hooks/useAppConfig'
import { useTranslations } from 'next-intl'

interface TagProductsWidgetProps {
  widget: StorefrontWidget<WidgetTagProducts>
  overrides?: OverridesContextType
}

export const CustomTagProductsWidget = ({ widget }: TagProductsWidgetProps) => {
  const t = useTranslations('CustomTagProductsWidget')
  const { handleProductRequest } = useProductRequest()
  const { handleAddToCart } = useCustomCart()
  const { isLoading, data } = useGetProductsQuery({
    filter: { tagIds: [widget.widget.pathObj.id] }
  })
  const appConfig = useAppConfig()

  const products = useMemo(() => {
    return data?.content.slice(0, 10) || []
  }, [data?.content])

  return (
    <TagProductsWidgetBase
      key={widget.widget.name}
      config={{
        title: widget.widget.title,
        ...(widget.widget.pathObj
          ? {
              headingLink: {
                title: t('showAll'),
                href: `${appConfig.paths.tagsUrlPath}/${widget.widget.pathObj.slug}`
              }
            }
          : {}),
        products,
        onProductRequest: handleProductRequest,
        deliveryOptions: appConfig.storeDeliveries,
        onProductBuy: handleAddToCart,
        paths: {
          productsUrlPath: appConfig.paths.productsUrlPath
        },
        itemCount: products.length,
        isLoading
      }}
    />
  )
}
